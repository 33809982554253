import React from "react";
import { Helmet } from "react-helmet";

function Metadata() {
  return (
    <Helmet>
      <title>Fredrik Olsen Kapoun - Utvikler</title>
      <meta
        name="description"
        content="Portfolio av Fredrik Olsen Kapoun. En utvikler med en passion for clean moderne designs"
      />
      <meta
        name="keywords"
        content="utvikler, frontend, front-end, fullstack, full-stack, ASP.NET, React, ferdigheter, c#, C# javascript, react, nettsider, design, flutter, app, portfolio, bachelor, Fredrik, Olsen, Kapoun, Fredrik Olsen Kapoun"
      />
      <meta property="og:title" content="Fredrik Olsen Kapoun - Utvikler" />
      <meta
        property="og:description"
        content="Portfolio av Fredrik Olsen Kapoun. En utvikler med en passion for clean moderne designs"
      />
      <meta
        property="og:image"
        content="https://images.pexels.com/photos/270404/pexels-photo-270404.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
      />
      <meta property="og:url" content="https://fredrikok.com" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:title"
        content="Fredrik Olsen Kapoun - Frontend Utvikler"
      />
      <meta
        name="twitter:description"
        content="Portfolio av Fredrik Olsen Kapoun. En utvikler med en passion for clean moderne designs"
      />
      <meta
        name="twitter:image"
        content="https://images.pexels.com/photos/270404/pexels-photo-270404.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
      />
    </Helmet>
  );
}

export default Metadata;
