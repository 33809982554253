import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import OnePost from "./components/OnePost";
import Error from "./pages/Error";
import WebPage from "./pages/WebPage";

import "./style/AppMobile.scss";
import "./style/navfooter.scss";
import Navbar from "./components/Navbar";

function App() {
  const [isDarkMode, setIsDarkMode] = useState(() => {
    const savedTheme = localStorage.getItem("theme");
    return savedTheme === "light" ? false : true;
  });

  useEffect(() => {
    document.body.className = isDarkMode ? "dark-mode" : "light-mode";
  }, [isDarkMode]);

  const toggleTheme = () => {
    const newIsDarkMode = !isDarkMode;
    setIsDarkMode(newIsDarkMode);
    localStorage.setItem("theme", newIsDarkMode ? "dark" : "light");
  };

  return (
    <>
      <div className={`App ${isDarkMode ? "dark-mode" : "light-mode"}`}>
        <Router>
          <Navbar isDarkMode={isDarkMode} toggleTheme={toggleTheme} />
          <Routes>
            <Route index element={<WebPage />} />
            <Route path="/web" element={<WebPage />} />
            <Route path="/:slug" element={<OnePost />} />
            <Route path="/*" element={<Error />} />
          </Routes>
        </Router>
      </div>
    </>
  );
}

export default App;
