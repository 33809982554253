import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { ReactSpinner } from "react-spinning-wheel";
import "react-spinning-wheel/dist/style.css";

import sanityClient from "../Client";

export default function AllPosts() {
  const [allPostsData, setAllPosts] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "post"]{
        title,
        description,
        slug,
        link,
        categories[]-> {
            title,
            description,
            mainImage{
              asset->{
                _id,
                url,
              }
            },
        },
        mainImage{
          asset->{
          _id,
          url
        }
      }
    }`
      )
      .then((data) => setAllPosts(data))
      .catch(console.error);
  }, []);

  if (!allPostsData) return;
  <div className="load-content">
    <ReactSpinner />
  </div>;

  return (
    <div className="projects-wrapper">
      <div className="projects">
        {allPostsData &&
          allPostsData?.map((post, index) => (
            <div className="product-box" key={index}>
              <Link
                className="link-box"
                to={"/" + post?.slug?.current}
                key={post?.slug?.current}
              >
                <img
                  className="project-img"
                  src={post?.mainImage.asset.url}
                  alt={post?.mainImage.asset._id}
                />
                <div className="product-text">
                  <h3>{post?.title}</h3>
                  <p>{post?.description}</p>
                  <h4>Ferdigheter</h4>
                  <div className="skill-cards">
                    {post?.categories?.map((category, index) => (
                      <div className="" key={index}>
                        <img
                          className="img-icons"
                          src={category?.mainImage?.asset?.url}
                          alt={category?.title}
                        ></img>
                      </div>
                    ))}
                  </div>
                </div>
              </Link>
            </div>
          ))}
      </div>
    </div>
  );
}
