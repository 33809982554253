import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import sanityClient from "../Client";
import imageUrlBuilder from "@sanity/image-url";
import BlockContent from "@sanity/block-content-to-react";
import Footer from "./Footer";
import { Dimmer, Loader, Image, Segment } from "semantic-ui-react";

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
  return builder.image(source);
}

export default function SinglePost() {
  const [singlePost, setSinglePost] = useState(null);
  const { slug } = useParams();

  useEffect(() => {
    sanityClient
      .fetch(
        `*[slug.current == "${slug}"]{
            title,
            _id,
            price,
            url,
            link,
            cdlink,
            slug,
            imagesGallery,
            mainImage{
                asset->{
                    _id,
                    url
                }
            },
            body,
            "name": author->name,
            "authorImage": author->image
        }`
      )
      .then((data) => setSinglePost(data[0]))
      .catch(console.error);
  }, [slug]);

  if (!singlePost)
    return (
      <div className="load-content">
        <Loader />
      </div>
    );

  return (
    <>
      <main className="webbody">
        <div className="breadcrumbs">
          <Link to="/">Home</Link> / {slug}
        </div>
        <section className="onepost-section">
          <div className="post-link">
            <img
              className="project-img-smaller"
              src={singlePost.mainImage.asset.url}
              alt={singlePost.mainImage.asset._id}
            />
            {singlePost.link || singlePost.cdlink ? <h2>Lenker</h2> : null}
            {singlePost.link ? (
              <a rel="noreferrer" target="_blank" href={singlePost.link}>
                Live server
              </a>
            ) : null}
            {singlePost.cdlink ? (
              <a rel="noreferrer" target="_blank" href={singlePost.cdlink}>
                Github
              </a>
            ) : null}
          </div>
          <div>
            <h2>{singlePost.title}</h2>
            <a href={singlePost.url} />
            <BlockContent
              blocks={singlePost.body}
              projectId={sanityClient.config().projectId}
            />
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
