import React from "react";
import { Link } from "react-router-dom";

function Error() {
  return (
    <div className="error">
      <div className="error-overlay">
        <h1>Error 404 : Page not found</h1>
        <Link to="/">Return Home</Link>
      </div>
    </div>
  );
}

export default Error;
