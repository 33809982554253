import React from "react";
import { FaSearch, FaEdit, FaArtstation } from "react-icons/fa";
import { AiOutlineLinkedin, AiFillMail, AiFillGithub } from "react-icons/ai";
import { CgProfile } from "react-icons/cg";
import { GiSkills, GiHamburgerMenu } from "react-icons/gi";
import { BsFillSunFill, BsFillMoonFill } from "react-icons/bs";

const Icon = ({ name, size = 24, color = "black" }) => {
  switch (name) {
    case "search":
      return <FaSearch size={size} color={color} />;
    case "edit":
      return <FaEdit size={size} color={color} />;
    case "artstation":
      return <FaArtstation size={size} color={color} />;
    case "linkedin":
      return <AiOutlineLinkedin size={size} color={color} />;
    case "github":
      return <AiFillGithub size={size} color={color} />;
    case "profile":
      return <CgProfile size={size} color={color} />;
    case "skills":
      return <GiSkills size={size} color={color} />;
    case "email":
      return <AiFillMail size={size} color={color} />;
    case "moon":
      return <BsFillMoonFill size={size} color={color} />;
    case "day":
      return <BsFillSunFill size={size} color={color} />;
    case "hamburger":
      return <GiHamburgerMenu size={size} color={color} />;
    default:
      return null;
  }
};

export default Icon;
