import React from "react";

//styles
import "../style/webpage.scss";
//component
import Footer from "../components/Footer";
import Metadata from "../components/Metadata";
//icons
import * as AiIcons from "react-icons/ai";
import * as BsIcons from "react-icons/bs";
import * as HiIcons from "react-icons/hi";
//img
import fref from "../img/fref200.webp";
import AllPosts from "../components/AllPosts";
import Icon from "../components/Icons";
import SkillIcons from "../components/SkillIcons";
import AllPost3D from "../components/AllPost3D";

function WebPage() {
  return (
    <>
      <Metadata />
      <div className="webbody">
        <main>
          {/* section 1 */}
          <h2 id="aboutme"></h2>
          {/* <div className="line"></div> */}
          {/* <section>
            <div className="section-header">
              <img
                className="profile-img"
                src={fref}
                alt="bilde av fredrik"
              ></img>

              <div className="aboutme-text">
                <div className="aboutme-text-left">
                  <p className="about-me-p">
                    <BsIcons.BsFillPersonFill />
                    Fredrik Olsen Kapoun
                  </p>
                  <p className="about-me-p">
                    <HiIcons.HiDesktopComputer />
                    Front-End Utvikler
                  </p>
                  <p className="about-me-p">
                    <AiIcons.AiFillMail />
                    <a href="mailto: fredrik99k@hotmail.com">
                      fredrik99k@hotmail.com
                    </a>
                  </p>
                </div>
                <div className="aboutme-text-right">
                  <SkillIcons />
                </div>
              </div>
            </div>
            <div className="p-text">
              <p>
                Jeg er en utvikler med en lidenskap for å lage gode interaktive
                opplevelser på nettet. Jeg har erfaring med HTML, CSS,
                JavaScript, og frontend-rammeverk som React.js. Jeg arbeider
                også med .NET for fullstack applikasjoner.
              </p>
            </div>
            <div className="icons">
              <a
                href="https://github.com/fredrikok"
                target="_blank"
                rel="noreferrer"
              >
                <Icon name="github" size={40} color="#ffffff" />
              </a>
              <a
                href="https://www.linkedin.com/in/fredrik-olsen-kapoun-738047202/"
                target="_blank"
                rel="noreferrer"
              >
                <Icon
                  className="iconhover"
                  name="linkedin"
                  size={40}
                  color="#ffffff"
                />
              </a>
            </div>
          </section> */}

          {/* Section 3 */}
          <h2 id="projects"> Utvikling - Prosjekter</h2>
          <div className="line"></div>

          {/* Projects */}

          <section className="section-projects">
            <AllPosts />
          </section>

          <div className="break"></div>

          {/* 3d modellering 
          <h2 id="projects"> 3D - modellering</h2>
          <div className="line"></div>
        */}
          {/* Projects for 3d */}

          {/* <section className="section-projects">
            <AllPost3D />
          </section> */}

          {/* Bottom */}
        </main>
        <div className="bottom-svg-contaimner">
          <div className="dark-color"></div>
          <svg
            className="svg-wave-bot"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1440 320"
          >
            <path
              opacity="1"
              d="M0,160L48,170.7C96,181,192,203,288,213.3C384,224,480,224,576,208C672,192,768,160,864,122.7C960,85,1056,43,1152,42.7C1248,43,1344,85,1392,106.7L1440,128L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
            ></path>
          </svg>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default WebPage;
