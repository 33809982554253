import React, { useState } from "react";
import { Link } from "react-router-dom";
import Icon from "../components/Icons";
import { GiHamburgerMenu } from "react-icons/gi";

function Navbar({ isDarkMode, toggleTheme }) {
  const [showNav, setShowNav] = useState(false);

  const handleToggleNav = () => {
    setShowNav(!showNav);
  };

  return (
    <nav id="nav-wrapweb">
      <div className="nav-wrapweb">
        <div className="nav-desktop">
          <div>
            <h3>
              <Link to="/web">Fredrik Olsen Kapoun</Link>
            </h3>
          </div>
          <div className="nav-link-wrap">
            {/* <a href="/web#aboutme">Om meg</a> */}
            {/* <a href="/web#projects">Prosjekter</a>tiv */}
            <div className="toggle-switch" onClick={toggleTheme}>
              <div
                className={`toggle-switch-slider ${
                  isDarkMode ? "dark-mode" : ""
                }`}
              >
                {isDarkMode ? (
                  <Icon name="moon" size={24} color="#ffffff" />
                ) : (
                  <Icon name="day" size={24} color="#ffffff" />
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="mobile-navbar-wrapper">
          <button className="hamburger-menu" onClick={handleToggleNav}>
            <GiHamburgerMenu />
          </button>
          <div className="hamburger-menu">
            <div className="toggle-switch" onClick={toggleTheme}>
              <div
                className={`toggle-switch-slider ${
                  isDarkMode ? "dark-mode" : ""
                }`}
              >
                {isDarkMode ? (
                  <Icon name="moon" size={24} color="#ffffff" />
                ) : (
                  <Icon name="day" size={24} color="#ffffff" />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={`nav-wrapmobile ${showNav ? "show-nav" : ""}`}>
          <a href="/" onClick={handleToggleNav}>
            Hjem
          </a>
          {/* <a href="#aboutme" onClick={handleToggleNav}>
            Om meg
          </a> */}
          {/* <a href="#projects" onClick={handleToggleNav}>
            Prosjekter
          </a> */}
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
