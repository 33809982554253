import React from 'react'

function Footer() {
  return (
    <>
    <footer>
        <p>Fredrik Olsen Kapoun | 2023</p> 
    </footer>
    </>
  )
}

export default Footer